<template>
    <div
        class="box--select"
        :class="{
            'is-invalid': config.error,
            disabled_cursor: disabled
        }"
    >
        <Multiselect
            :id="id"
            :showPointer="config.showPointer ?? false"
            :showNoResults="config.showNoResults ?? false"
            :selectedLabel="config.selectedLabel ?? ''"
            :deselectLabel="config.deselectLabel ?? ''"
            :placeholder="config.placeholder ?? ''"
            :selectLabel="config.selectLabel ?? ''"
            :showNoOptions="config.showNoOptions ?? false"
            :track-by="config.trackBy ?? 'value'"
            :label="config.label ?? 'label'"
            v-model="valueModel"
            :options="optionsSelect"
            :multiple="config.multiple ?? false"
            :open-direction="config.openDirection ?? 'auto'"
            :disabled="disabled"
            :allow-empty="config.multiple ?? false"
            :taggable="config.taggable ?? false"
            :searchable="config.searchable ?? true"
            :deactivate="config.deactivate"
            :custom-label="handleCustomLabel"
            @select="handleSelected"
            @tag="handleAddTag"
            @open="handleOpen"
            @close="handleClose"
        ></Multiselect>
        <i
            v-if="valueModel && !config.isRemove && !config?.multiple && (valueModel?.[config?.trackBy ?? 'value'] || customLabel)"
            @click="handleRemove"
            class="fa fa-times-circle icon-close"
        ></i>
        <div v-if="config.loading ?? false" class="loader__select">
            <span class="loader"></span>
        </div>
        <div v-if="config?.error" class="invalid-feedback d-block">
            <span v-if="config?.isRequired">
                {{
                    $t('validateField.requiredSelect', {
                        field: config.errorField
                    })
                }}
            </span>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'MultiselectComponent',
    components: {
        Multiselect
    },
    props: {
        id: {
            type: String,
            default: 'multi-select'
        },
        value: {
            type: [Object, String, Number, Array],
            default: () => ({})
        },
        options: {
            type: Array,
            default: () => []
        },
        customLabel: {
            type: String,
            default: ''
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    action: '',
                    openDirection: 'bottom',
                    isRemove: false,
                    showPointer: false,
                    showNoResults: false,
                    selectedLabel: '',
                    deselectLabel: '',
                    placeholder: '',
                    selectLabel: '',
                    showNoOptions: false,
                    multiple: false,
                    taggable: false,
                    searchable: true,
                    trackBy: 'value',
                    label: 'label',
                    error: false,
                    loading: false,
                    isRequired: false,
                    existObject: false,
                    deactivate: false,
                    valueSync: null
                };
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            optionsSelect: [],
            valueModel: null || [],
            generatedNumbersDefault: 1
        };
    },
    watch: {
        '$props.config': {
            handler(newConfig) {
                this.config = newConfig;
            },
            immediate: true
        },

        '$props.value': {
            handler(valReceived) {
                if (!this.config.multiple) {
                    this.valueModel = valReceived;
                } else {
                    this.valueModel = valReceived || [];
                }
            },
            immediate: true
        },
        '$props.options': {
            handler(newOptions) {
                this.optionsSelect = newOptions;
            },
            immediate: true
        },
        valueModel: {
            handler(valSelected) {
                let config = this.config;
                config.valueSync = valSelected;
                this.$emit('update:config', config);
                this.$emit('update:value', valSelected);
            },
            immediate: true
        },
        optionsSelect: {
            handler(newOptions) {
                this.$emit('update:options', newOptions);
            }
        }
    },
    methods: {
        handleOpen(id) {
            this.$nextTick(() => {
                const dropdownContent = this.$el.querySelector('.multiselect__content-wrapper');
                if (dropdownContent) {
                    dropdownContent.addEventListener('scroll', this.scrollHandler);
                } else {
                    console.warn('Dropdown content not found.');
                }
            });
            this.$emit('open', id);
        },

        handleClose(value, id) {
            const dropdownContent = this.$el.querySelector('.multiselect__content-wrapper');
            if (dropdownContent) {
                dropdownContent.removeEventListener('scroll', this.scrollHandler);
            }
            this.$emit('update:value', this.valueModel);
            this.$emit('close', { value, id });
        },

        scrollHandler(event) {
            const bottomReached = event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 10;
            this.$emit('scroll', bottomReached);
        },

        handleSelected(selectedOption, id) {
            if (!this.config.multiple) {
                this.valueModel = selectedOption;
            }
            let config = this.config;
            config.error = false;
            config.isRequired = false;
            config.valueSync = selectedOption;
            this.$emit('update:value', this.valueModel);
            this.$emit('select', selectedOption, id);
            this.$emit('update:config', config);
        },

        handleAddTag(newOption) {
            if (this.optionsSelect.length > 0) {
                this.generatedNumbersDefault = this.optionsSelect[this.optionsSelect.length - 1].id + 1;
            }
            let newTag = {
                id: this.generatedNumbersDefault,
                name: newOption
            };
            this.optionsSelect.push(newTag);
            this.$emit('addTag', newTag);
        },

        handleRemove(evt) {
            if (this.$props.disabled) return;
            evt.preventDefault();
            if (!this.config?.existObject) {
                this.valueModel = null;
            }
            let config = this.config;
            config.valueSync = null;
            this.$emit('remove');
        },

        handleCustomLabel(option) {
            if (!this?.customLabel) return option[this.config.label];
            return this.$t(`${this?.customLabel}.${option}`);
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
.loader__select {
    position: absolute;
    top: 6px;
    right: 30px;
    z-index: 999;
    display: flex;
    align-items: center;
}
.loader {
    width: 25px;
    height: 25px;
    border: 3px solid #ffffff;
    border-bottom-color: #c1c1c1;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
}
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #fff;
    animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}
</style>

<style lang="scss">
.disabled_cursor {
    .multiselect.multiselect--disabled {
        opacity: 0.7;
        .multiselect__tags {
            color: #2c353d !important;
        }
    }
}
.box--select {
    position: relative;
    width: 100%;
    min-width: 100px;
    .multiselect__single {
        padding-right: 25px;
    }
    .icon-close {
        cursor: pointer;
        position: absolute;
        right: 12px;
        top: 10px;
        background: #fff;
        z-index: 9;
        font-size: 17px;
        color: #999;
        transition: all 0.5s;
        &:hover {
            color: #636363;
        }
    }
}
</style>
